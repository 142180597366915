import React from 'react'
import './App.css'

const App = () => (
  <div className="App">
    <h1>🚧 Under Construction 🚧</h1>
    <p>I'm doing a bit of construction here, but feel free to get in touch with me:</p>
    <div className="links">
      <a href="https://github.com/cocolson" target="_blank" rel="noopener noreferrer">GitHub</a>
      <a href="https://x.com/cobycolson" target="_blank" rel="noopener noreferrer">X (Twitter)</a>
      <a href="https://www.linkedin.com/in/cocolson/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
    </div>
  </div>
)

export default App
